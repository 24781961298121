import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

function CreditScoreTermsOfUse({ isOpen, toggle, back, submitIdentityData }) {
  const handleSubmit = () => {
    submitIdentityData();
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} className="modal-header p-4 w-100">
        <span>Credit Score Terms of Use</span>
      </ModalHeader>
      <ModalBody className="p-4">
        <p className="text-center">
          By submitting this registration form, I understand that I am providing written instructions in accordance with
          the Fair Credit Reporting Act, authorizing Best Money Moves LLC and Equifax Consumer Services LLC, to access
          and obtain my credit information from my Equifax Information Services LLC credit report to (i) confirm my
          identity; (ii) display my credit score to me; and (iii) calculate average credit scores for employees at my
          place of employment enrolled in Best Money Moves&apos; services and provide anonymized average score
          information to my employer.
        </p>
      </ModalBody>
      <ModalFooter className="p-4">
        <Button color="secondary" onClick={back}>
          Back
        </Button>
        <Button style={{ width: '70%' }} color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
}

CreditScoreTermsOfUse.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  back: PropTypes.func,
  submitIdentityData: PropTypes.func,
};

export default CreditScoreTermsOfUse;
