import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';
import { ClientOnly } from 'remix-utils/client-only';
import { useRouteLoaderData } from '@remix-run/react';
import states from '../utils/states.js';
import '../style/components/creditWidget.css';

const initialFormData = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  ssn1: '',
  ssn2: '',
  ssn3: '',
  mobileNumber: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: '',
  zipCode: '',
};

function CreditScoreSignUpModal({ isOpen, toggle, setShowModal, setShowTermsModal, setIdentityData }) {
  const { recaptchaSiteKey } = useRouteLoaderData('root');
  const [showSSN, setShowSSN] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const [formData, setFormData] = useState(initialFormData);
  const formRef = useRef(null);

  const innerToggle = () => {
    toggle();
    if (!isOpen) {
      resetForm();
    }
  };

  const handleSSNToggle = () => setShowSSN(!showSSN);

  const handleRecaptchaChange = (value) => setRecaptchaValue(value);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSSNChange = (e, nextFieldId) => {
    const { name, value, maxLength } = e.target;
    const numericValue = value.replace(/\D/g, '');
    setFormData({
      ...formData,
      [name]: numericValue,
    });
    if (numericValue.length >= maxLength && nextFieldId) {
      document.getElementById(nextFieldId).focus();
    }
  };

  const validateForm = (event) => {
    event.preventDefault();

    const requiredFields = [
      'firstName',
      'lastName',
      'dateOfBirth',
      'ssn1',
      'ssn2',
      'ssn3',
      'mobileNumber',
      'addressLine1',
      'city',
      'state',
      'zipCode',
    ];

    let allFieldsFilled = true;
    requiredFields.forEach((field) => {
      if (!formData[field] || formData[field].trim() === '') {
        allFieldsFilled = false;
      }
    });

    if (!allFieldsFilled || !recaptchaValue) {
      setErrorMessage('Please fill out all of the required fields.');
    } else {
      setErrorMessage('');
      setIdentityData(formData);
      setShowModal(false);
      setShowTermsModal(true);
    }
  };

  const resetForm = () => {
    setFormData(formData);
    setRecaptchaValue(null);
    setErrorMessage('');
    if (formRef.current) {
      formRef.current.reset();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={innerToggle}>
      <ModalHeader tag="h4" className="p-4 w-100 modal-title" toggle={toggle}>
        <span>Get Credit Score</span>
      </ModalHeader>
      <ModalBody style={{ padding: '1.875rem' }}>
        <div style={{ width: '90%' }} className="disclaimer">
          <span>
            To retrieve your credit score we need some information to verify your identity. This information is not
            stored by Best Money Moves.
          </span>
          <h4 className="mt-3">Personal Information</h4>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <Form ref={formRef} onSubmit={validateForm}>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              type="text"
              name="firstName"
              id="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              type="text"
              name="lastName"
              id="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="dateOfBirth">Date of Birth</Label>
            <Input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="ssn">SSN</Label>
            <Row>
              <Col>
                <Input
                  type={showSSN ? 'text' : 'password'}
                  name="ssn1"
                  id="ssn1"
                  maxLength={3}
                  value={formData.ssn1}
                  onChange={(e) => handleSSNChange(e, 'ssn2')}
                  pattern="\d*"
                  required
                />
              </Col>
              <Col>
                <Input
                  type={showSSN ? 'text' : 'password'}
                  name="ssn2"
                  id="ssn2"
                  maxLength={2}
                  value={formData.ssn2}
                  onChange={(e) => handleSSNChange(e, 'ssn3')}
                  pattern="\d*"
                  required
                />
              </Col>
              <Col>
                <Input
                  type={showSSN ? 'text' : 'password'}
                  name="ssn3"
                  id="ssn3"
                  maxLength={4}
                  value={formData.ssn3}
                  onChange={(e) => handleSSNChange(e)}
                  pattern="\d*"
                  required
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" name="displaySSN" onChange={handleSSNToggle} /> Display Social Security Number
            </Label>
          </FormGroup>
          <div className="disclaimer">
            <span>
              Your Social Security number helps us locate your Equifax credit report, which will help us verify your
              identity.
            </span>
          </div>
          <FormGroup>
            <Label className="mt-3" for="mobileNumber">
              Mobile Number
            </Label>
            <InputMask mask="(999) 999-9999" value={formData.mobileNumber} onChange={handleChange}>
              {(inputProps) => <Input {...inputProps} type="text" name="mobileNumber" id="mobileNumber" required />}
            </InputMask>
          </FormGroup>
          <div className="disclaimer">
            <span>
              We may use your mobile number to confirm your identity if you select to use 2-step mobile verification.
            </span>
            <h4 className="mt-3">Current Address</h4>
          </div>
          <FormGroup>
            <Label for="addressLine1">Address Line 1</Label>
            <Input
              type="text"
              name="addressLine1"
              id="addressLine1"
              value={formData.addressLine1}
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="addressLine2">Address Line 2</Label>
            <Input
              type="text"
              name="addressLine2"
              id="addressLine2"
              value={formData.addressLine2}
              onChange={handleChange}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="city">City</Label>
                <Input type="text" name="city" id="city" value={formData.city} onChange={handleChange} required />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="state">State</Label>
                <Input type="select" name="state" id="state" value={formData.state} onChange={handleChange} required>
                  {states.map((state) => (
                    <option key={state.name} value={state.abbreviation}>
                      {state.abbreviation}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="zipCode">Zip Code</Label>
                <Input
                  type="text"
                  name="zipCode"
                  id="zipCode"
                  value={formData.zipCode}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup className="d-flex justify-content-center mt-3">
            <ClientOnly fallback={<span>Loading...</span>}>
              {() => (
                <>{recaptchaSiteKey && <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={handleRecaptchaChange} />}</>
              )}
            </ClientOnly>
          </FormGroup>
          <ModalFooter className="p-5">
            <Button style={{ width: '100%' }} color="primary" type="submit">
              Continue
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
}

CreditScoreSignUpModal.propTypes = {
  isOpen: PropTypes.bool,
  setIdentityData: PropTypes.func,
  setShowModal: PropTypes.func,
  setShowTermsModal: PropTypes.func,
  toggle: PropTypes.func,
};

export default CreditScoreSignUpModal;
